import React from "react";
import Layout from "../layout/Layout";
import ConsentProcessingBlock from "../blocks/static/ConsentProcessingBlock.js";
import SEO from "../components/seo";

import enMessages from '../i18n/consentProcessingPD/en.js'
import ruMessages from '../i18n/consentProcessingPD/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const AboutPage = ({ pathContext: { locale } }) => {
    return (
        <Layout locale={locale}>
            <SEO
                title={messages[locale].seo.title}
                description={messages[locale].seo.description}
            />
            <ConsentProcessingBlock locale={locale}/>
        </Layout>
    )
};

export default AboutPage;
