export default {
    "seo": {
        "title": "Consent to the processing of Personal Data",
        "description": "Bank 131 – Russia’s newest digital bank. API-based compliant payment solution for Russia, mass payouts, payouts to the self-employed, online payments, and banking as a service."
    },
    "consentProcessingBlock": {
        "title": "Consent to the processing of Personal Data",
        "text":
        "<p>I, acting voluntarily, of my own free will and in my personal interest, give my consent for Bank 131, Limited Liability Company, located at the address: 38 Nekrasov St., Kazan, Republic of Tatarstan, Russia, Index 420012, (hereinafter referred to as Bank), to process, including by means of automation tools, my personal data according to the requirement of the Federal Law dated 27 July 2006 No. 152-FZ On personal data (including acquisition, recording, systematization, accumulation, storage, adjustment (updating, modification), retrieval, utilization, transfer (distribution, disclosure, access), impersonation, blocking, deletion, destruction of personal data). </p>" +

        "<p>I express my consent to mixed processing, using automation tools or without, of my following personal data: last name, given name, patronymic; telephone; e-mail address; telegram id; metadata; cookie file data; cookie identifiers; IP addresses; information about browser and operating system, and any other information submitted to the Bank.</p>" +

        "<p>The personal data I have specified are to be submitted with the purposes of contacting me in order to provide information about the Bank's services, about procedures of accepting banking services and other interaction focused on conclusion of contractual relations; providing information about results of review of applications by the Bank; informing me of new products and services of the Bank, about promotional events, loyalty programs and other campaigns focused on promotion of goods, works and (or) services arranged by Bank and (or) by their counterparties by sending promotional and (or) informational materials by any communication links; transferring my personal data to the Bank's counterparties with the purpose of offering me their goods, works, and/or services, or informing me about actions they arrange that are focused on promotion of goods, works, and/or services; verifying the validity of data I submit, contacting me with the aforementioned purposes, including the involvement of the third parties. </p>" +

        "<p>With the aforementioned purposes, I authorize Bank by this consent to transfer my personal data to the third parties and engage in processing by the third parties specified herein with the purpose of processing my personal data by such third parties as: MS-Contact, LLC, address: 10 Motalnyy, room 21, Nizhny Novgorod, Index 603140.</p>" +

        "<p>The consent to personal data processing shall be valid upon its signing and effective during the entire period of contractual obligations between the Bank and me, as well as for five years after the fulfillment of contractual obligations. Upon the expiry of the indicated period, the validity of the consent shall be deemed extended for every next five years in the absence of any information about its revocation.  </p>" +

        "<p>The consent may be revoked following a written statement sent to the address of the Bank by a registered letter with recorded delivery or handed personally to a Bank representative with receipt.</p>" +

        "<p>In case of my revocation of the consent to the processing of my personal data, the Bank may not stop processing personal data required for performance of contracts signed with me, as well as in other cases specified by legislation.</p>"
    }
}
