export default {
    "seo": {
        "title": "Согласие на обработку персональных данных",
        "description": "Банк 131 — цифровой банк, платежные решения для бизнеса: прием платежей онлайн, выплаты и расчеты с физическими лицами и самозанятыми. Лицензия ЦБ РФ, официальный партнер ФНС."
    },
    "consentProcessingBlock": {
        "title": "Согласие на обработку персональных данных",
        "text":
        "<p>Я, действуя свободно, своей волей и в своем интересе, выражаю: Обществу с ограниченной ответственностью «Банк 131», находящемуся по адресу: 420012, Российская Федерация, Республика Татарстан, г. Казань, ул. Некрасова, д. 38 (далее – Банк), согласие на обработку, в том числе с использованием средств автоматизации, своих персональных данных в соответствии с требованиями ФЗ от 27.07.2006г. № 152-ФЗ «О персональных данных» (включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных). </p>" +

        "<p>Выражаю согласие на смешанную обработку с использованием и без использования средств автоматизации следующих моих персональных данных: фамилия, имя, отчество; телефон; адрес электронной почты; telegram id; метаданные; данные cookie-файлов; cookie-идентификаторы;  IP-адреса; сведения о браузере и операционной системе и любую иную информацию, представленную Банку.</p>" +

        "<p>Указанные мной персональные данные предоставляются в целях: осуществления связи со мной для предоставления информации об услугах Банка, о порядке принятия на банковское обслуживание и иного взаимодействия, направленного на заключение договорных отношений; предоставления информации о результатах рассмотрения Банком заявлений; информирования меня о новых продуктах и услугах Банка о маркетинговых акциях, программах лояльности и иных мероприятиях, направленных на продвижение товаров, работ и (или) услуг, организуемых Банком, и (или) его контрагентами, посредством направления рекламных и (или) информационных материалов по любым каналам связи; передачи персональных данных контрагентам Банка в целях предложения последними мне товаров, работ и (или) услуг либо информирования меня о проводимых ими мероприятиях, направленных на продвижение товаров, работ и (или) услуг; осуществления проверки достоверности представленных мной данных, осуществления контактов со мной в указанных выше целях, в том числе с участием третьих лиц. </p>" +

        "<p>В вышеуказанных целях Настоящим согласием уполномочиваю Банк на передачу моих персональных данных третьим лицам и поручаю обработку третьими лицами, указанным в настоящем согласии, в целях обработки моих персональных данных такими третьими лицами: ООО «МС-Контакт», Адрес: 603140, г. Нижний Новгород, пер. Мотальный, д. 10, пом. 21.</p>" +

        "<p>Согласие на обработку персональных данных действует с момента его подписания и распространяется на весь период существования между мной и Банком договорных обязательств, а также в течение пяти лет после исполнения договорных обязательств. По истечении указанного срока действие согласия считается продленным на каждые следующие пять лет при отсутствии сведений о его отзыве.  </p>" +

        "<p>Согласие может быть отозвано на основании письменного заявления, направленного в адрес Банка по почте заказным письмом с уведомлением о вручении либо врученного лично под расписку представителю Банка.</p>" +

        "<p>В случае отзыва мною согласия на обработку моих персональных данных, Банк вправе не прекращать обработку персональных данных, необходимых для исполнения заключенных со мной договоров, а также в иных случаях, предусмотренных законодательством.</p>"
    }
}
